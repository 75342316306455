
import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'



const InteriorDesignPage = () => (
  <Layout>
    <SEO title="Interior Design" keyword={'Interior Design','Design Interior','Learn Interior Design'} />
    <p>Let us understand the Interior design in very easy way</p>
  </Layout>
)



export default InteriorDesignPage
